import React from 'react';

// eslint-disable-next-line import/no-unresolved
import { SwiperSlide } from 'swiper/react';
import InfoSectionPanel from 'components/InfoSectionPanel';
import InstructionPanel from 'components/InstructionPanel';
import LocationPanel from 'components/LocationPanel';
import MapPanel from 'components/MapPanel';
import OverviewPanel from 'components/OverviewPanel';
import PhasePanel from 'components/PhasePanel';
import PickupAddressPanel from 'components/PickupAddressPanel';
import RegistrationPanel from 'components/RegistrationPanel';
import ErrorPanel from 'components/SadPanels/ErrorPanel';
import SchedulePickupPanel from 'components/SchedulePickupPanel';
import VideoPanel from 'components/VideoPanel';
import {
  isTypeInfoTextPanel,
  isTypeLocationPanel,
  isTypeMapPanel,
  isTypeInstructionPanel,
  isTypePhase,
  isTypeVideo,
  isTypeOverviewPanel,
  isTypeRegistrationPanel,
  isTypePickupAddressPanel,
  isTypeSchedulePickupPanel,
} from 'contentmodels';
import { Panel } from 'utils/constants/baseExperienceTypes';
interface ParserProps {
  panel: Panel;
  order: number;
  phases: string[];
  openHelpModal: () => void;
  handleIsPanelSwipableChange: (newState: boolean) => void;
  isPanelSwipable: boolean;
}

export const getComponentType = (props: ParserProps) => {
  const { panel, order, phases, openHelpModal, handleIsPanelSwipableChange, isPanelSwipable } = props;

  if (isTypePhase<'WITHOUT_UNRESOLVABLE_LINKS', string>(panel)) {
    return (
      <SwiperSlide data-history={order} key={order}>
        <PhasePanel panel={panel} order={order} phases={phases} openHelpModal={openHelpModal} />
      </SwiperSlide>
    );
  }

  if (isTypeInfoTextPanel<'WITHOUT_UNRESOLVABLE_LINKS', string>(panel)) {
    return (
      <SwiperSlide data-history={order} key={order}>
        <InfoSectionPanel panel={panel} openHelpModal={openHelpModal} order={order} />
      </SwiperSlide>
    );
  }

  if (isTypeVideo<'WITHOUT_UNRESOLVABLE_LINKS', string>(panel)) {
    return (
      <SwiperSlide
        data-history={order}
        key={order}
        className={panel.fields.timerTime && !isPanelSwipable ? 'swiper-no-swiping' : undefined}
      >
        <VideoPanel
          panel={panel}
          progressBarStepNum={panel.panelNumber}
          progressBarStepTotal={panel.totalPanels}
          phases={phases}
          order={order}
          openHelpModal={openHelpModal}
          setIsPanelSwipable={handleIsPanelSwipableChange}
        />
      </SwiperSlide>
    );
  }

  if (isTypeLocationPanel<'WITHOUT_UNRESOLVABLE_LINKS', string>(panel)) {
    return (
      <SwiperSlide data-history={order} key={order} className="swiper-no-swiping">
        <LocationPanel
          panel={panel}
          phaseName={panel.phaseName}
          progressBarStepNum={panel.panelNumber}
          progressBarStepTotal={panel.totalPanels}
          phases={phases}
          openHelpModal={openHelpModal}
          order={order}
        />
      </SwiperSlide>
    );
  }

  if (isTypeInstructionPanel<'WITHOUT_UNRESOLVABLE_LINKS', string>(panel)) {
    return (
      <SwiperSlide data-history={order} key={order}>
        <InstructionPanel
          panel={panel}
          phaseName={panel.phaseName}
          progressBarStepNum={panel.panelNumber}
          progressBarStepTotal={panel.totalPanels}
          phases={phases}
          openHelpModal={openHelpModal}
          order={order}
        />
      </SwiperSlide>
    );
  }

  if (isTypeOverviewPanel<'WITHOUT_UNRESOLVABLE_LINKS', string>(panel)) {
    return (
      <SwiperSlide data-history={order} key={order}>
        <OverviewPanel panel={panel} openHelpModal={openHelpModal} order={order} />
      </SwiperSlide>
    );
  }

  if (isTypeMapPanel<'WITHOUT_UNRESOLVABLE_LINKS', string>(panel)) {
    return (
      <SwiperSlide data-history={order} key={order} className="swiper-no-swiping">
        <MapPanel
          panel={panel}
          phaseName={panel.phaseName}
          progressBarStepNum={panel.panelNumber}
          progressBarStepTotal={panel.totalPanels}
          phases={phases}
          order={order}
          openHelpModal={openHelpModal}
        />
      </SwiperSlide>
    );
  }

  if (isTypeRegistrationPanel<'WITHOUT_UNRESOLVABLE_LINKS', string>(panel)) {
    return (
      <SwiperSlide data-history={order} key={order} className="swiper-no-swiping">
        <RegistrationPanel panel={panel} openHelpModal={openHelpModal} order={order} />
      </SwiperSlide>
    );
  }

  if (isTypePickupAddressPanel<'WITHOUT_UNRESOLVABLE_LINKS', string>(panel)) {
    return (
      <SwiperSlide data-history={order} key={order} className="swiper-no-swiping">
        <PickupAddressPanel panel={panel} order={order} openHelpModal={openHelpModal} />
      </SwiperSlide>
    );
  }

  if (isTypeSchedulePickupPanel<'WITHOUT_UNRESOLVABLE_LINKS', string>(panel)) {
    return (
      <SwiperSlide data-history={order} key={order} className="swiper-no-swiping">
        <SchedulePickupPanel panel={panel} openHelpModal={openHelpModal} order={order} />
      </SwiperSlide>
    );
  }

  return (
    <SwiperSlide data-history={order} key={order}>
      <ErrorPanel errorCode="404" openHelpModal={openHelpModal} />
    </SwiperSlide>
  );
};
