import React, { useEffect, useId, useState } from 'react';
import * as amplitude from '@amplitude/analytics-browser';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
// eslint-disable-next-line import/no-unresolved
import { useSwiper } from 'swiper/react';
import LocationIcon from 'assets/LocationIcon';
import LoadingPanel from 'components/SadPanels/LoadingPanel';
import StepsProgressBar from 'components/SharedComponents/StepsProgressBar';
import { TypeLocationPanelWithoutUnresolvableLinksResponse } from 'contentmodels';
import { useGlobalStore } from 'contexts/mainGlobalContext';
import { ThemeColors, ThemeFonts } from 'styles/constants';
import {
  InputDividingText,
  LocationContainer,
  LocationContentWrapper,
  LocationDetectionButton,
  LocationPanelContainer,
  LocationPanelTextBodyWrapper,
  LocationPanelTextWrapper,
} from './locationPanel.styles';
import AddressAutocomplete, { USER_LOCATION_TEXT } from '../SharedComponents/AddressAutocomplete';

interface props {
  panel: TypeLocationPanelWithoutUnresolvableLinksResponse;
  phaseName: string;
  progressBarStepNum: number;
  progressBarStepTotal: number;
  phases: string[];
  order: number;
  openHelpModal: () => void;
}

const LocationPanel = (props: props) => {
  const swiper = useSwiper();
  const { setDropOffLocationSearchOrigin } = useGlobalStore();
  const [locationServicesButtonEnabled, setLocationServicesButtonEnabled] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState(false);
  const id = useId();

  useEffect(() => {
    swiper.on('slideChangeTransitionEnd', function () {
      if (swiper.activeIndex == props.order) {
        amplitude.track('Find location page loaded');
      }
    });
  }, [swiper]);

  const onLocationServicesClick = () => {
    setIsLoading(true);
    navigator.geolocation.getCurrentPosition(
      (position) => {
        // Location permissions granted.
        setLocationServicesButtonEnabled(true);
        setDropOffLocationSearchOrigin(position.coords);
        localStorage.setItem('dropOffLatitude', position.coords.latitude.toString());
        localStorage.setItem('dropOffLongitude', position.coords.longitude.toString());
        localStorage.setItem('dropOffAddress', USER_LOCATION_TEXT);
        amplitude.track('Find locations near you clicked');
        swiper.slideNext();
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
        // Location permissions denied/unavailable.
        setLocationServicesButtonEnabled(false);
      }
    );
  };

  const onPlaceSelectEvent = (event: google.maps.places.PlaceResult | null) => {
    if (event?.geometry?.location) {
      const country = event.address_components?.find((component) => component.types.includes('country'))?.short_name;
      localStorage.setItem('dropOffLatitude', event.geometry.location.lat().toString());
      localStorage.setItem('dropOffLongitude', event.geometry.location.lng().toString());
      localStorage.setItem('dropOffAddress', event.formatted_address!);
      localStorage.setItem('dropOffCountry', country!);
      setDropOffLocationSearchOrigin({
        latitude: event.geometry.location.lat(),
        longitude: event.geometry.location.lng(),
        formattedAddress: event.formatted_address,
        country: country,
      });
      amplitude.track('Address entered');
      swiper.slideNext();
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <LoadingPanel openHelpModal={props.openHelpModal} />}
      {!isLoading && (
        <LocationPanelContainer>
          <StepsProgressBar {...props} />
          <LocationContentWrapper>
            <LocationPanelTextWrapper>
              {props.panel.fields.titleText && (
                <ThemeFonts.HeadlineSmall color={ThemeColors.TassoBlue}>
                  {props.panel.fields.titleText}
                </ThemeFonts.HeadlineSmall>
              )}
              {props.panel.fields.bodyText && (
                <LocationPanelTextBodyWrapper key={id}>
                  {documentToReactComponents(props.panel.fields.bodyText)}
                </LocationPanelTextBodyWrapper>
              )}
            </LocationPanelTextWrapper>
            <LocationContainer>
              <AddressAutocomplete onPlaceSelect={onPlaceSelectEvent} />
              <InputDividingText style={{ alignSelf: 'center' }} color={ThemeColors.HeavyGray}>
                or
              </InputDividingText>
              <LocationDetectionButton
                data-testid="location-detection-button"
                onClick={locationServicesButtonEnabled ? onLocationServicesClick : undefined}
                disabled={!locationServicesButtonEnabled}
              >
                <LocationIcon width={24} height={24} />
                <ThemeFonts.BodyLarge
                  color={locationServicesButtonEnabled ? ThemeColors.TassoDarkBlue : ThemeColors.DarkGray}
                >
                  {locationServicesButtonEnabled ? 'Find locations near you' : 'Location services are unavailable'}
                </ThemeFonts.BodyLarge>
              </LocationDetectionButton>
            </LocationContainer>
          </LocationContentWrapper>
        </LocationPanelContainer>
      )}
    </>
  );
};

export default LocationPanel;
