import React, { useEffect } from 'react';
import * as amplitude from '@amplitude/analytics-browser';

// eslint-disable-next-line import/no-unresolved
import { useSwiper } from 'swiper/react';
import { HeaderContainer } from 'components/GenericStyles/generic.styles';
import BackButton from 'components/SharedComponents/BackButton';
import HelpButton from 'components/SharedComponents/HelpButton';
import LabelledTitle from 'components/SharedComponents/LabelledTitle';
import NextButton from 'components/SharedComponents/NextButton';
import { TypeInfoTextPanelWithoutUnresolvableLinksResponse } from 'contentmodels';
import { ThemeColors, ThemeFonts } from 'styles/constants';
import {
  BackgroundIconStyles,
  LogoStyles,
  ContentWrapper,
  InfoPanelContainerStyles,
  FooterWrapper,
  BodyWrapper,
} from './infoSectionPanel.styles';
const InfoSectionPanel = (props: {
  panel: TypeInfoTextPanelWithoutUnresolvableLinksResponse;
  order: number;
  openHelpModal: () => void;
}) => {
  const {
    subText,
    backgroundIcon,
    tertiaryText,
    nextButtonText,
    titleText,
    nextButtonPrefixText,
    nextButtonLink,
    includeBackButton,
    sectionLabel,
    name,
    companyLogo,
  } = props.panel.fields;

  const swiper = useSwiper();
  useEffect(() => {
    swiper.on('slideChangeTransitionEnd', function () {
      if (swiper.activeIndex == props.order) {
        amplitude.track(`${name} page loaded`);
      }
    });
  }, [swiper]);

  return (
    <InfoPanelContainerStyles>
      <HeaderContainer>
        {includeBackButton && <BackButton />}
        <HelpButton {...props} fullWidth={!includeBackButton} />
      </HeaderContainer>
      {companyLogo?.fields.file?.url && companyLogo?.fields.title && (
        <LogoStyles>
          <img src={companyLogo.fields.file.url} alt={companyLogo.fields.title} />
        </LogoStyles>
      )}
      <BodyWrapper>
        <ContentWrapper>
          <div>
            {subText && <ThemeFonts.TitleLarge color={ThemeColors.HeavyGray}>{subText}</ThemeFonts.TitleLarge>}
            {titleText && (
              <LabelledTitle titleText={titleText} titleTextProps={{ margin: '12px 0' }} labelText={sectionLabel} />
            )}
          </div>
          {tertiaryText && <ThemeFonts.BodyLarge color={ThemeColors.HeavyGray}>{tertiaryText}</ThemeFonts.BodyLarge>}
        </ContentWrapper>
        {backgroundIcon?.fields.file?.url && backgroundIcon?.fields.title && (
          <BackgroundIconStyles>
            <img src={backgroundIcon.fields.file.url} alt={backgroundIcon.fields.title} />
          </BackgroundIconStyles>
        )}
      </BodyWrapper>

      <FooterWrapper>
        {nextButtonPrefixText && <ThemeFonts.BodyLarge>{nextButtonPrefixText}</ThemeFonts.BodyLarge>}
        <NextButton nextButtonText={nextButtonText} nextButtonLink={nextButtonLink} />
      </FooterWrapper>
    </InfoPanelContainerStyles>
  );
};

export default InfoSectionPanel;
