import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as amplitude from '@amplitude/analytics-browser';

import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
// eslint-disable-next-line import/no-unresolved
import { useSwiper } from 'swiper/react';
import CircleArrowIcon from 'assets/CircleArrowIcon';
import SadFaceIcon from 'assets/SadFaceIcon';
import HelpButton from 'components/SharedComponents/HelpButton';
import { TypeRegistrationPanelWithoutUnresolvableLinksResponse } from 'contentmodels';
import { ThemeColors, ThemeFonts } from 'styles/constants';
import {
  BodyWrapper,
  CodeInput,
  CodeWrapper,
  InputDash,
  InputWrapper,
  RegistrationPanelContainer,
} from './registrationPanel.styles';
import { submitActivationCode } from './submitActivation';
import {
  ErrorWrapper,
  FooterWrapper,
  PrimaryButtonStyles,
  TipIconStyles,
  TipIconWrapper,
  TipWithIconWrapper,
  TipWrapper,
} from '../GenericStyles/generic.styles';

const RegistrationPanel = (props: {
  panel: TypeRegistrationPanelWithoutUnresolvableLinksResponse;
  order: number;
  openHelpModal: () => void;
}) => {
  const swiper = useSwiper();

  const { titleText, tips, inputInstructions, errorText } = props.panel.fields;
  const [firstHalf, setFirstHalf] = useState<string>('');
  const [secondHalf, setSecondHalf] = useState<string>('');
  const [token, setToken] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [canRegister, setCanRegister] = useState<boolean>(false);
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const updateCode = (value: string, isFirst: boolean) => {
    const trimmedValue = value.replace(/\s/g, '');
    if (isFirst) {
      setFirstHalf(trimmedValue);
    } else {
      setSecondHalf(trimmedValue);
    }
  };

  useEffect(() => {
    swiper.on('slideChangeTransitionEnd', function () {
      if (swiper.activeIndex == props.order) {
        amplitude.track(`Registration page loaded`);
      }
    });
  }, [swiper]);

  const submitCode = async () => {
    setRefreshReCaptcha((r: boolean) => !r);
    const code = firstHalf.concat(secondHalf);
    setIsLoading(true);
    const apiResponse = await submitActivationCode(code.toUpperCase(), token, errorText);
    localStorage.setItem('activationCode', code);
    setIsLoading(false);

    if (typeof apiResponse === 'string') {
      setErrorMessage(apiResponse);
    } else {
      // We don't want to allow end users to activate thier kit if they have already completed collection
      if (apiResponse.collectionComplete) {
        setErrorMessage(errorText || 'Invalid registration. Please try again.');
      } else {
        localStorage.setItem('activationCode', apiResponse.activationCode);
        swiper.slideNext();
      }
    }
  };

  const onVerify = useCallback(
    (token: string) => {
      return setToken(token);
    },
    [setToken]
  );

  const secondInputRef = useRef<HTMLInputElement>(null);

  const handleKeyUp = (key: string): void => {
    if (key !== 'Backspace' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
      if (firstHalf.length == 4) {
        secondInputRef.current?.focus();
      }
    }
  };

  useEffect(() => {
    if (firstHalf.length === 4 && secondHalf.length === 4) {
      setCanRegister(true);
    } else {
      setCanRegister(false);
    }
  }, [firstHalf, secondHalf, setCanRegister]);

  return (
    <RegistrationPanelContainer>
      <HelpButton {...props} />
      <BodyWrapper>
        {titleText && (
          <ThemeFonts.DisplayMedium color={ThemeColors.TassoBlue} margin="32px 32px 0 0">
            {titleText}
          </ThemeFonts.DisplayMedium>
        )}
        <CodeWrapper>
          <ThemeFonts.BodyLarge color={ThemeColors.HeavyGray}>
            {inputInstructions || 'Enter the code from your kit here:'}
          </ThemeFonts.BodyLarge>
          <InputWrapper>
            <CodeInput
              data-testid="code-input-1"
              maxLength={4}
              value={firstHalf}
              onChange={(e) => updateCode(e.target.value, true)}
              onKeyUp={(event) => handleKeyUp(event.key)}
              aria-label="Activation Code First Input"
            />
            <InputDash />
            <CodeInput
              data-testid="code-input-2"
              maxLength={4}
              value={secondHalf}
              onChange={(e) => updateCode(e.target.value, false)}
              ref={secondInputRef}
              aria-label="Activation Code Second Input"
            />
          </InputWrapper>
          {errorMessage && (
            <ErrorWrapper data-testid="error-message" aria-label="Error Message">
              <SadFaceIcon />
              <ThemeFonts.BodyLarge>{errorMessage}</ThemeFonts.BodyLarge>
            </ErrorWrapper>
          )}
        </CodeWrapper>
      </BodyWrapper>
      <FooterWrapper>
        {tips &&
          tips.map((tip, index: number) => (
            <TipWrapper key={index}>
              <TipWithIconWrapper>
                {tip?.fields.tipIcon && (
                  <img src={tip.fields.tipIcon?.fields.file?.url} alt={tip.fields.tipIcon.fields.description} />
                )}
                <div>
                  {tip?.fields.titleIconPosition !== undefined && (
                    <TipIconWrapper $titleIconPosition={tip.fields.titleIconPosition}>
                      {tip.fields.titleText && <ThemeFonts.LabelLarge>{tip.fields.titleText}</ThemeFonts.LabelLarge>}
                      <TipIconStyles $titleIconPosition={tip.fields.titleIconPosition}>
                        {tip.fields.titleIcon?.fields.file?.url && tip.fields.titleIcon.fields.description && (
                          <img
                            src={tip.fields.titleIcon?.fields.file?.url}
                            alt={tip.fields.titleIcon.fields.description}
                          />
                        )}
                      </TipIconStyles>
                    </TipIconWrapper>
                  )}
                  {tip?.fields.textBody && <ThemeFonts.BodyMedium>{tip.fields.textBody}</ThemeFonts.BodyMedium>}
                </div>
              </TipWithIconWrapper>
            </TipWrapper>
          ))}
        <GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={refreshReCaptcha} />
        <PrimaryButtonStyles
          data-testid="register-button"
          margin="32px 0 0 0"
          disabled={!canRegister}
          onClick={submitCode}
          $isLoading={isLoading}
          aria-label="Register Button"
        >
          <ThemeFonts.LabelLarge>Register{isLoading && '...'}</ThemeFonts.LabelLarge>
          {!isLoading && <CircleArrowIcon />}
        </PrimaryButtonStyles>
      </FooterWrapper>
    </RegistrationPanelContainer>
  );
};

export default RegistrationPanel;
